/**
 * Decrypts the given encrypted data using AES-CBC algorithm (256-bit key), via the Web-Crypto API.
 *
 * @async
 * @function decryptData
 * @param {string} data - The encrypted data as a base64-encoded string.
 * @param {string} iv_hex - The initialization vector (IV) as a hexadecimal string.
 * @param {string} key_b64 - The encryption key as a base64-encoded string.
 * @returns {Promise<ArrayBuffer>} A promise that resolves to the decrypted data as an ArrayBuffer.
 * @throws {Error} If an error occurs during the decryption process.
 */



export async function decryptBase64(data, iv_hex, key_b64) {

    const encryptedBytes = Uint8Array.from(atob(data), (c) => c.charCodeAt(0));
    const iv = new Uint8Array(iv_hex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));
    const binaryKey = Uint8Array.from(atob(key_b64), (c) => c.charCodeAt(0));

    const cryptoKey = await window.crypto.subtle.importKey('raw', binaryKey, { name: 'AES-CBC' }, false, ['decrypt']);

    const decryptedData = await window.crypto.subtle.decrypt(
        {
            name: 'AES-CBC',
            iv: iv,
        },
        cryptoKey,
        encryptedBytes
    );
    return decryptedData;
}
